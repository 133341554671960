.contact {
  padding: 0;
  padding-right: 20px;
  background: linear-gradient(
    to top,
    #279cd2,
    rgba(255, 255, 255, 0.442) 50%,
    rgba(63, 61, 86, 0.9960784314)
  );
  min-height: 80vh;
}

.contact .title {
  transform: translateY(40px);
  text-align: center;
  color: white;
 
}
.contact .content {
  padding: 10px;
  min-height: 60vh;
  margin-top: 3%;
  display: flex;
  justify-content: end;
  align-items: center;
  background-image: url(../../../assets/img/message.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 5%;
  animation-name: contact;
  animation-duration: 4s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  transition: ease-in-out;
}
.contact .content .mobile-form {
  display: none;
}
.contact .content form {
  width: 400px;
  min-height: 65vh;
  box-shadow: 0 0 15px 5px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact .content form input::placeholder {
  color: #279dd2;
  padding: 0 5px;
}
.contact .content form textarea {
  border-radius: 0 15px;

}

.contact .content form textarea::placeholder {
  color: #279dd2;
  padding-left: 5px;
}

.contact .content form button {
  background-color: #279dd2;
  transition: 0.7s ease-in;
  box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.2);
}

.contact .content form button:hover {
  border: 1px solid;
}

@keyframes contact {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 160%;
  }
}

@media screen and (max-width: 720px) {
  .contact {
    font-size: 75%;
  }
  .contact .title {
    font-size: 25px;
    font-weight: 500;
    transform: translateY(40px);
  }
  .contact .content form input::placeholder{
    font-size: 75%;
    
}
.contact .content form textarea::placeholder{
    font-size: 75%;
    
}
}


@media screen and (max-width: 550px) {

.contact{
    padding-right: 0;
}
.contact .title{
    font-size: 1rem;
    transform: translateY(10px);
}
.contact .content{
    margin: 0;
    animation: none;

}

.contact .content .disk-form{
    display: none;
}
.contact .content .mobile-form{
    display: flex;
}



}
