
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  background-color: black;
  min-height: 100vh;

}
::-webkit-scrollbar{
  display: none;
}