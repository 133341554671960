.about {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(../../../assets/img/bg14.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 80vh;
  color: white;
  margin: 0 5%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
}

.about h1 {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 15%;
  border-radius: 50%;
  box-shadow: 0 0 10px 10px rgba(255, 255, 255, 0.506);
}
.about div {
  padding-top: 2rem;
  text-align: center;
}
.about div p {
  font-size: 20px;
}
.about div h3 {
  font-size: 30px;
}
.about div p:nth-last-child(1) {
  font-size: 25px;
  color: rgba(255, 255, 255, 0.659);
}

@media screen and (max-width: 992px) {
  .about {
    background-image: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0)),
      url(../../../assets/img/bg.jpg);

    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    margin: 0;
  }
  .about h1 {
    width: 200px;
    height: 200px;
  }
}

@media screen and (max-width: 550px) {
  .about h1 {
    width: 100px;
    height: 100px;
    font-size: 20px;
  }
  .about div p {
    font-size: 1rem;
    text-align: center;
  }
  .about div p:nth-last-child(1) {
    font-size: 20px;
  }
}
