.header{
    position: sticky;
    top: 0;
    z-index: 99; border-radius: 20px 20px 0 0;
    color: white;
  }
  .custom-navbar {
    color: white; /* Set the text color to white */
  }
  
  


#basic-nav-dropdown {
  
    color: white;
  
   
  }
  

  #arabicNav{
  direction: rtl;

  }