.project {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.project .card {
    transition: transform 0.3s ease; /* Smooth transition */
  }
  
  .project .card:hover {
    transform: scale(1.05); /* Scale up the card by 5% */
  }
