
.app{

  width: 90%;
  min-height: 80%;
  margin: 3% auto;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px 2px white;
  position: relative;

}


