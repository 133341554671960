#arabic-footer{
direction: rtl;
    width: 100%;
    height: 60px;
    background-color: cornflowerblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 0 0 20px 20px;

}

.footer p {
    padding: 0;
    margin: 0;
}
.footer p span{
    color: aliceblue;
}
.footer .social-icons{
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: white;
}
.footer .social-icons a{
    transition: 0.7s ease;
}

.footer .social-icons a:hover{
    transform: scale(1.5);
}



@media screen and (max-width: 720px) {
  
  
    #arabic-footer {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
    }
   .footer p {
      font-size: 0.7rem;
    }

    .footer   .social-icons a {
      font-size: 20px;
    }
  }